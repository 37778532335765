import { useState, useEffect, lazy } from 'react';
import axios from 'axios';
import { Row, Col, Layout, Modal, Form, Input, Radio, message, Typography, Result, Avatar } from 'antd';
// import backgroundWallpaper from "../../assets/images/invisageBackground.jpg"
import Intercom from '@intercom/messenger-js-sdk';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import SvgAltlabPortfolios from '../../assets/images/PageIcons/AltlabPortfolios';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import {
    DashboardOutlined,
    AreaChartOutlined,
    PieChartOutlined,
    ContactsOutlined,
    DatabaseOutlined,
    BarChartOutlined,
    DotChartOutlined,
    RadarChartOutlined,
    FundOutlined,
    ClusterOutlined,
    HeatMapOutlined,
    TeamOutlined,
    UserOutlined,
    DeploymentUnitOutlined,
    PartitionOutlined,
    ProjectOutlined,
    MergeCellsOutlined,
    HddOutlined,
    LineChartOutlined,
    InteractionOutlined,
    BellOutlined,
} from '@ant-design/icons';

// Routing
import { Navigate, useRoutes, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../Settings/useAuth';

import Sidebar from '../_app/_components/Sidebar/main';
import Topbar from '../_app/_components/Topbar/main';
import Tickerization from '../_app/Tickerization/main';
import ScenarioModeling from '../_app/ScenarioModeling/main'
import DataDelivery from '../_app/DataDelivery/main'
import FundHoldings from '../_app/Prospects/FundHoldings';

const { Content, Footer } = Layout;

const Login = lazy(() => import('../__logins/login'))
// const Topbar = React.lazy(() => import("../_app/_components/Topbar_o/main"))
// const Sidebar = React.lazy(() => import("../_app/_components/Sidebar_o/Sidebar"))
const Provider = lazy(() => import("../_app/Providers/Provider"))
const Baskets = lazy(() => import("../_app/Baskets/Baskets"))
const BasketsCreation = lazy(() => import("../_app/Baskets/Basketcreation/BasketCreation"))
const DataManagement = lazy(() => import("../_app/DataManagement/DataManagement"))
const Prospectors = lazy(() => import("../_app/Prospects/main"))
const FundsData = lazy(() => import("../_app/Prospects/FundsDetails"))
// const Secmaster = React.lazy(() => import("../_app/Secmaster/main"))
const Market = lazy(() => import("../_app/Market/main"))
const SignalGeneration = lazy(() => import("../_app/SignalGeneration/main"))
const DataVisualizer = lazy(() => import("../_app/DataVisualizer/main"))
const ThirdPartyIntegration = lazy(() => import("../_app/ThirdPartyIntegration/main"))
const FundamentalEdge = lazy(() => import("../_app/FundamentalEdge/main"))
const MyAltlab360 = lazy(() => import("../_app/MyAltLab360/main"))
const Portfolio = lazy(() => import("../_app/MyAltLab360/_components/Portfolios"))
const PageNotFound = lazy(() => import("../_app/404/main"))
const TrySymlink = lazy(() => import("../_app/TrySymlink/Main"))


function RequireAuth({ children }) {
    const { authed } = useAuth();
    const location = useLocation();
    return authed === true ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
}

export function App() {

    const [login, setlogin] = useState(null)
    const [AllowedPages, setAllowedPages] = useState([])
    const [page404, setpage404] = useState('/404')
    const location = useLocation();
    const Navigate_u = useNavigate();

    const [email, setemail] = useState(null)
    const [collapsed, setcollapsed] = useState(false)

    const [visible, setVisible] = useState(false);

    const PasswordCreateForm = ({ visible, onCreate, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                open={visible}
                title="Create a new password"
                okText="Create"
                cancelText="Cancel"
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="NEW_Password_form_in_modal"
                >
                    <Form.Item
                        name="password"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                                pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter New Password" />
                    </Form.Item>
                    <Form.Item
                        name="password_again"
                        label="Password Again"
                        rules={[
                            {
                                required: true,
                                message: 'Same as above',
                                pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Re-type the password" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const onCreate = (values) => {
        if (values.password !== values.password_again) {
            message.error("Passwords do not match!")
            return
        }
        axios({
            method: 'post',
            url: '/users/reset_password/',
            data: {
                'username': email,
                'password': values.password
            }
        })
            .then(res => {
                message.success(res?.data?.message);
                Navigate_u("/", { replace: true })
            })
            .catch(err => {
                message.error(err?.response?.data?.message)
            })
            .finally(() => {
                setVisible(false);
                setemail(null)
            })

    };

    const checkUrlForToken = (urlCheck) => {
        let page = '';
        let pageURL = '';
        if (urlCheck[3] === 'reset_password') {
            page = 'reset'
            pageURL = 'reset_password'
        } else if (urlCheck[3] === 'forgot_password') {
            page = 'forgot'
            pageURL = 'forgot_password'
        }
        if (page !== '' && (urlCheck[3] === 'reset_password' || urlCheck[3] === 'forgot_password')) {
            axios.post('/users/check_code/', {
                'confirmation_code': urlCheck[4],
                'page': page
            })
                .then(res => {
                    setemail(res.data.email)
                    setVisible(true)
                })
                .catch(err => {
                    message.error(err?.response?.data?.message)
                    setTimeout(() => {
                        Navigate_u("/login", { replace: true })
                    }, 2000);
                })
        }
    }

    if (AllowedPages.length > 0) {
        if (window.location.pathname == '/500') {
            localStorage.clear();
            Navigate_u("/login", { replace: true })
        }
        if (!AllowedPages.includes(window.location.pathname)) {
            setpage404(window.location.pathname)
            Navigate_u("/404", { replace: true })
        }
    }

    useEffect(() => {
        // effect
        // document.body.style.backgroundImage =  "url(../../assets/images/invisageBackground.jpg) no-repeat center center fixed"; 
        localStorage.setItem("defaultURL", JSON.stringify(location))
        if (localStorage.getItem('login') && localStorage.getItem('login').length > 0) {
            setlogin(JSON.parse(localStorage.getItem('login')));
            let temp = ['/login', '/reset_password', '/sign-up', '/forgot_password', '/logout', '/404', '/500']
            JSON.parse(localStorage.getItem('login')).user.allowed_pages.map(x => {
                if (x == "Dashboard") { temp.push('/providers') }
                if (x == "Portfolio-Creation") { temp.push('/portfolio-creation') }
                if (x == "Basket") { temp.push('/basket') }
                if (x == "Rfolio") { temp.push('/rfolio') }
                if (x == "Security-Master") { temp.push('/market') }
                if (x == "Data Management") { temp.push('/clientmanagement') }
                if (x == "Prospects") { temp.push('/prospector') }
                if (x == "Funds") { temp.push('/funds') }
                if (x == "FundHoldings") {temp.push('/fundHoldings')}
                if (x == "SymLink") { temp.push('/symlink') }
                if (x == "Scenario Modeling") { temp.push("/scenariomodeling") }
                if (x == "Fundamental Edge") { temp.push('/fundamentaledge') }
                if (x == "AltLab360") { temp.push('/altlab360') }
                if (x == "TrySymlink"){temp.push('/TrySymlink')}
            })
            setAllowedPages(temp)

        }
        else {

            if (location.pathname != '/public' && location.pathname != '/sign-up' && location.pathname.split('/')[1] != "reset_password" && location.pathname.split('/')[1] != "forgot_password" && location.pathname != '/TrySymlink') Navigate_u("/login", { replace: true })
        }
        checkUrlForToken(window.location.href.split("/"))
        return () => {
            // cleanup
        }
    }, [])

    const getAllUserDateByID = (data) => {
        localStorage.setItem('login', JSON.stringify(data));
        setlogin(data)
    }

    let rou = [
        { SSRKEY: "User-Profile", invisible: true, path: '/', element: login ? login.user.allowed_pages.includes("Rfolio") ? <Navigate to="/rfolio" replace state={{ path: location.pathname }} /> : <Navigate to="/providers" replace state={{ path: location.pathname }} /> : <Navigate to="/login" replace state={{ path: location.pathname }} /> },
        { SSRKEY: "User-Profile", invisible: true, path: "/login", element: login ? <Navigate to="/" replace state={{ path: location.pathname }} /> : <Login getAllUserDateByID={getAllUserDateByID} /> },
        { SSRKEY: "User-Profile", invisible: true, path: "/sign-up", element: <Login getAllUserDateByID={getAllUserDateByID} /> },
        { SSRKEY: "TrySymlink", invisible: true, path: "/TrySymlink", element: <TrySymlink /> },
        { SSRKEY: "Dashboard", invisible: false, path: "/providers", name: "Providers", icon: <DashboardIcon style={{ fontSize: '20px' }} />, element: <RequireAuth><Provider /></RequireAuth> },
        { SSRKEY: "Basket", invisible: true, name: "Public-Baskets", icon: <BarChartOutlined style={{ fontSize: '20px' }} />, path: '/public', element: <Baskets /> },
        { SSRKEY: "Basket", invisible: true, name: "Baskets-Creation", icon: <BarChartOutlined style={{ fontSize: '20px' }} />, path: '/basket-Creation', element: <RequireAuth><BasketsCreation /></RequireAuth> },
        { SSRKEY: "Portfolio-Creation", invisible: true, name: "Portfolio-Creation", icon: <BarChartOutlined style={{ fontSize: '20px' }} />, path: '/portfolio-creation', element: <RequireAuth><Portfolio /></RequireAuth> },
        { SSRKEY: "Rfolio", invisible: false, name: "AltLab Portfolios", icon: <PieChartOutlined style={{ fontSize: '20px' }} />, path: '/rfolio', element: <RequireAuth><Baskets /></RequireAuth> },
        { SSRKEY: "Security-Master", invisible: false, name: "Market Data", icon: <AreaChartOutlined style={{ fontSize: '20px' }} />, path: '/market', element: <RequireAuth><Market /></RequireAuth> },
        { SSRKEY: "Data Management", invisible: false, name: "Client Management", icon: <GroupsIcon style={{ fontSize: '20px' }} />, path: '/clientmanagement', element: <RequireAuth><DataManagement /></RequireAuth> },
        { SSRKEY: "Prospects", invisible: false, name: "Prospector", icon: <AccountCircleIcon style={{ fontSize: '20px' }} />, path: '/prospector', element: <RequireAuth><Prospectors /></RequireAuth> },
        { SSRKEY: "FundHoldings", invisible: false, name: "Fund Holdings", icon: <FundOutlined style={{ fontSize: '20px' }} />, path: '/fundHoldings', element: <RequireAuth><FundHoldings /></RequireAuth> },
        { SSRKEY: "SymLink", invisible: false, name: "SymLink", icon: <DatasetLinkedOutlinedIcon style={{ fontSize: '20px' }} />, path: '/symlink', element: <RequireAuth><Tickerization /></RequireAuth> },
        // { path: "*", element: login ? <Navigate to="/" replace state={{ path: location.pathname }} /> : <Navigate to="/login" replace state={{ path: location.pathname }} /> },
        // {
        //   path: 'users',
        //   element: <Users />,
        //   children: [
        //     { path: '/', element: <UsersIndex /> },
        //     { path: ':id', element: <UserProfile /> },
        //     { path: 'me', element: <OwnUserProfile /> },
        //   ]
        // }
        // { SSRKEY: "Prospects", invisible: false, name: "Data Delivery", icon: <HeatMapOutlined style={{fontSize:'20px'}} />, path: '/DataDelivery', element: <RequireAuth><DataDelivery client={login?.user?.client?.id} /></RequireAuth> }
        // { SSRKEY: "Basket", invisible: false, name: "My Portfolios", icon: <BarChartOutlined style={{fontSize:'20px'}} />, path: '/basket', element: <RequireAuth><Baskets /></RequireAuth> },
        // { SSRKEY: "Benchmark", invisible: false, name: "Benchmarks", icon: <DotChartOutlined style={{fontSize:'20px'}} />, path: '/benchmark', element: <RequireAuth><Baskets /></RequireAuth> },
        // { SSRKEY: "ESG Portfolios", invisible: false, name: "ESG Baskets", icon: <RadarChartOutlined style={{fontSize:'20px'}} />, path: '/esgbasket', element: <RequireAuth><Baskets /></RequireAuth> },
        // { SSRKEY: "User Portfolios", invisible: false, name: "User Portfolios", icon: <FundOutlined style={{fontSize:'20px'}} />, path: '/userportfolio', element: <RequireAuth><Baskets /></RequireAuth> },
        // { SSRKEY: "Prospects", invisible: false, name: "Prospects", icon: <ContactsOutlined style={{fontSize:'20px'}} />, path: '/Prospects', element: <RequireAuth><Prospects /></RequireAuth> },

    ]
    // if (login && login?.user?.id === 1) rou.push({ SSRKEY: "User-Profile", invisible: false, name: "Data Visualizer", icon: <HeatMapOutlined style={{fontSize:'20px'}} />, path: '/datavisualizer', element: <RequireAuth><DataVisualizer /></RequireAuth> },)
    // if (login && login?.user?.id === 1) rou.push({ SSRKEY: "Prospects", invisible: false, name: "Signal Generation", icon: <ClusterOutlined style={{fontSize:'20px'}} />, path: '/SignalGeneration', element: <RequireAuth><SignalGeneration /></RequireAuth> })
    // if (login && login?.user?.id === 1) rou.push({ SSRKEY: "Prospects", invisible: false, name: "Third Party Integration", icon: <MergeCellsOutlined style={{fontSize:'20px'}} />, path: '/ThirdPartyIntegration', element: <RequireAuth><ThirdPartyIntegration login={login} /></RequireAuth> })
    // if (login && login?.user?.client?.id === 1) rou.push({ SSRKEY: "Prospects", invisible: false, name: "ProspectsNew", icon: <ContactsOutlined style={{fontSize:'20px'}} />, path: '/ProspectsNew', element: <RequireAuth><ProspectsNew /></RequireAuth> })
    // if (login && login?.user?.client?.id === 1) rou.push({ SSRKEY: "Funds", invisible: false, name: "Funds", icon: <FundOutlined style={{ fontSize: '20px' }} />, path: '/funds', element: <RequireAuth><FundsData /></RequireAuth> })
    // rou.push({ SSRKEY: "Funds", invisible: false, name: "Fund Holdings", icon: <FundOutlined style={{ fontSize: '20px' }} />, path: '/fundHoldings', element: <RequireAuth><FundHoldings /></RequireAuth> })
    // rou.push({ SSRKEY: "SymLink", invisible: false, name: "SymLink", icon: <DatasetLinkedOutlinedIcon style={{ fontSize: '20px' }} />, path: '/symlink', element: <RequireAuth><Tickerization /></RequireAuth> })
    if (login && login?.user?.client?.id === 1) rou.push({ SSRKEY: "Scenario Modeling", invisible: false, name: "Scenario Modeling", icon: <ViewInArIcon style={{ fontSize: '20px' }} />, path: '/scenariomodeling', element: <RequireAuth><ScenarioModeling /></RequireAuth> })
    rou.push({ SSRKEY: "Fundamental Edge", invisible: false, name: "Fundamental Edge", icon: <LineChartOutlined style={{ fontSize: '20px' }} /> })
    // rou.push({ SSRKEY: "Fundamental Edge", invisible: false, name: "OLD Fundamental Edge", icon: <LineChartOutlined style={{ fontSize: '20px' }} />, path: '/fundamentaledge', element: <RequireAuth><FundamentalEdge /></RequireAuth> })
    rou.push({ SSRKEY: "Basket", invisible: false, name: "My Portfolios", icon: <BarChartOutlined style={{ fontSize: '20px' }} />, path: '/basket', element: <RequireAuth><Baskets /></RequireAuth> })
    rou.push({ SSRKEY: "AltLab360", invisible: false, name: "My Datasets", icon: <InteractionOutlined style={{ fontSize: '20px' }} />, path: '/altlab360', element: <RequireAuth><MyAltlab360 /></RequireAuth> })
    rou.push({ SSRKEY: "User-Profile", invisible: true, name: "404 Page", path: `/404`, element: <RequireAuth><PageNotFound /></RequireAuth> })
    rou.push({ SSRKEY: "User-Profile", invisible: false, name: "Notifications", icon: <BellOutlined style={{ fontSize: '20px' }} />, path: '/Notifications', element: <RequireAuth><></></RequireAuth> })
    rou.push({
        SSRKEY: "User-Profile", invisible: false, name: "Profile", icon:
            <Avatar
                size={26}
                src={login?.user?.profile_picture_url ? login.user.profile_picture_url : 'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4'}
            // icon={<UserOutlined />} 
            />,
        path: '/Profile', element: <RequireAuth><></></RequireAuth>
    })
    let routesList = useRoutes(rou);
    const removeCookies = () => {
        // Get all cookies as an array
        const cookies = document.cookie.split(";");
      
        // Iterate through each cookie
        for (let cookie of cookies) {
          // Get the cookie name
          const cookieName = cookie.split("=")[0].trim();
      
          // Set the cookie expiration date to a past date to delete it
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      };

    useEffect(() => {
        // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+process.env.REACT_APP_API_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
        
        removeCookies();

        const userData = JSON.parse(localStorage.getItem('login'));
        
        if (userData) {
            var userEmail = userData.user.username;
            var userName = userData.user.first_name;
            var userCreatedAt = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        }
        
        Intercom({
            api_base: process.env.REACT_APP_API_BASE,
            app_id: process.env.REACT_APP_API_ID,
            // first_name:userName,
            name: userName,
            email: userEmail,
            created_at: userCreatedAt
        });
    }, [login])


    return (
        <Layout id='app-layout' className='background-transparent' style={{ padding: '0px' }} >
            {login &&
                <div style={{ height: '100vh', overflow: "auto" }}>
                    <Sidebar login={login} routesList={rou} />
                </div>
            }
            <Layout className="site-layout" style={{ height: '100vh', overflow: "auto" }} >
                {login ? <Topbar login={login} /> : <></>}
                <Content style={{ margin: `0 ${login ? '15px' : '0px'}` }}>
                    {routesList}
                    {/* <Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Bill</Breadcrumb.Item>
                        </Breadcrumb> */}

                    {/* <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            Bill is a cat.
                        </div> */}

                    <Footer className='is-center is-footer'>Althub ©{new Date().getFullYear()} All Right Reserved <Typography.Text code type='warning'>v5.2.0</Typography.Text></Footer>
                </Content>

            </Layout>
            <PasswordCreateForm
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </Layout>
    );
}